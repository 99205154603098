import fixture from 'can-fixture';

fixture('GET /api/news', () => {
	return [
		{
			id: 1,
			title: '1 Dubai brings home two wins this year at the EGR B2B Awards including Mobile Supplier ',
			image: '/data/news/bc_thumb.png',
			about: 'Bonanza Crush',
			detail_image: '/data/news/news_bonanza.png',
			desc:
				'1 After much deliberation and competition, the judges finally announced the winners of this year’s EGR B2B \n' +
				'Awards, with Dubai bringing home two award wins – including Mobile Supplier.\n' +
				'Dubai achieved a win in the Mobile Supplier category, ahead of 13 other suppliers looking to take home \n' +
				'the trophy. The judges commented that “Dubai have been the guys to beat this year. Sale aside, their \n' +
				'growth and innovation has allowed them to become mainstream on so many operators, so quickly. \n' +
				'Excellent work by them.”\n' +
				'This year’s joint entry win for Dubai and NetEnt in the Innovation in Slot Provision beat 13 other \n' +
				'prospective suppliers. The category looks to celebrate product differentiation, user experience, and \n' +
				'product development and deliverability judges were impressed by the “fantastic combination of \n' +
				'expertiseand experience” of the brands. Judges said there were “some hugely successfulnew launches in a \n' +
				'very competitive market”, and the award recognises the collaborative development of Gonzo’s Quest \n' +
				'Megaways™, Kulta-Jaska Megaways™ and Dragon’s Fire INFINIREELS™.\n' +
				'Evolution itself was awarded Live Casino Supplier of the Year, beating eight other shortlisted suppliers in \n' +
				'an incredible unbroken run of 12 wins in that category, stretching back to when the EGR B2B Awards first \n' +
				'launched in 2010',
			date: 'July 8, 2021',
			article_from: 'https://www.google.com',
		},
		{
			id: 2,
			title: '2 Dubai brings home two wins this year at the EGR B2B Awards including Mobile Supplier ',
			image: '/data/news/wws_thumb.png',
			about: 'Wild West Saloon',
			detail_image: '/data/news/news_wws.png',
			desc:
				'2 After much deliberation and competition, the judges finally announced the winners of this year’s EGR B2B \n' +
				'Awards, with Dubai bringing home two award wins – including Mobile Supplier.\n' +
				'Dubai achieved a win in the Mobile Supplier category, ahead of 13 other suppliers looking to take home \n' +
				'the trophy. The judges commented that “Dubai have been the guys to beat this year. Sale aside, their \n' +
				'growth and innovation has allowed them to become mainstream on so many operators, so quickly. \n' +
				'Excellent work by them.”\n' +
				'This year’s joint entry win for Dubai and NetEnt in the Innovation in Slot Provision beat 13 other \n' +
				'prospective suppliers. The category looks to celebrate product differentiation, user experience, and \n' +
				'product development and deliverability judges were impressed by the “fantastic combination of \n' +
				'expertiseand experience” of the brands. Judges said there were “some hugely successfulnew launches in a \n' +
				'very competitive market”, and the award recognises the collaborative development of Gonzo’s Quest \n' +
				'Megaways™, Kulta-Jaska Megaways™ and Dragon’s Fire INFINIREELS™.\n' +
				'Evolution itself was awarded Live Casino Supplier of the Year, beating eight other shortlisted suppliers in \n' +
				'an incredible unbroken run of 12 wins in that category, stretching back to when the EGR B2B Awards first \n' +
				'launched in 2010',
			date: 'July 8, 2021',
			article_from: 'https://www.google.com',
		},
		{
			id: 3,
			title: '3 Dubai brings home two wins this year at the EGR B2B Awards including Mobile Supplier ',
			image: '/data/news/ssp_thumb.png',
			about: 'Princess of Sakura',
			detail_image: '/data/news/news_sakura.png',
			desc:
				'3 After much deliberation and competition, the judges finally announced the winners of this year’s EGR B2B \n' +
				'Awards, with Dubai bringing home two award wins – including Mobile Supplier.\n' +
				'Dubai achieved a win in the Mobile Supplier category, ahead of 13 other suppliers looking to take home \n' +
				'the trophy. The judges commented that “Dubai have been the guys to beat this year. Sale aside, their \n' +
				'growth and innovation has allowed them to become mainstream on so many operators, so quickly. \n' +
				'Excellent work by them.”\n' +
				'This year’s joint entry win for Dubai and NetEnt in the Innovation in Slot Provision beat 13 other \n' +
				'prospective suppliers. The category looks to celebrate product differentiation, user experience, and \n' +
				'product development and deliverability judges were impressed by the “fantastic combination of \n' +
				'expertiseand experience” of the brands. Judges said there were “some hugely successfulnew launches in a \n' +
				'very competitive market”, and the award recognises the collaborative development of Gonzo’s Quest \n' +
				'Megaways™, Kulta-Jaska Megaways™ and Dragon’s Fire INFINIREELS™.\n' +
				'Evolution itself was awarded Live Casino Supplier of the Year, beating eight other shortlisted suppliers in \n' +
				'an incredible unbroken run of 12 wins in that category, stretching back to when the EGR B2B Awards first \n' +
				'launched in 2010',
			date: 'July 8, 2021',
			article_from: 'https://www.google.com',
		},
		{
			id: 4,
			title: '4 Dubai brings home two wins this year at the EGR B2B Awards including Mobile Supplier ',
			image: '/data/news/log_thumb.png',
			about: 'Lamp of Gene',
			detail_image: '/data/news/news_lamp.png',
			desc:
				'4 After much deliberation and competition, the judges finally announced the winners of this year’s EGR B2B \n' +
				'Awards, with Dubai bringing home two award wins – including Mobile Supplier.\n' +
				'Dubai achieved a win in the Mobile Supplier category, ahead of 13 other suppliers looking to take home \n' +
				'the trophy. The judges commented that “Dubai have been the guys to beat this year. Sale aside, their \n' +
				'growth and innovation has allowed them to become mainstream on so many operators, so quickly. \n' +
				'Excellent work by them.”\n' +
				'This year’s joint entry win for Dubai and NetEnt in the Innovation in Slot Provision beat 13 other \n' +
				'prospective suppliers. The category looks to celebrate product differentiation, user experience, and \n' +
				'product development and deliverability judges were impressed by the “fantastic combination of \n' +
				'expertiseand experience” of the brands. Judges said there were “some hugely successfulnew launches in a \n' +
				'very competitive market”, and the award recognises the collaborative development of Gonzo’s Quest \n' +
				'Megaways™, Kulta-Jaska Megaways™ and Dragon’s Fire INFINIREELS™.\n' +
				'Evolution itself was awarded Live Casino Supplier of the Year, beating eight other shortlisted suppliers in \n' +
				'an incredible unbroken run of 12 wins in that category, stretching back to when the EGR B2B Awards first \n' +
				'launched in 2010',
			date: 'July 8, 2021',
			article_from: 'https://www.google.com',
		},
	];
});

fixture('GET /api/news/more/{page}', function () {
	// if (2 > parseInt(request.data.id)) {
	//     return [];
	// }

	return [
		{
			id: 5,
			title: '5 Dubai brings home two wins this year at the EGR B2B Awards including Mobile Supplier ',
			image: '/data/news/bc_thumb.png',
			about: 'Bonanza Crush',
			detail_image: '/data/news/news_bonanza.png',
			desc:
				'5 After much deliberation and competition, the judges finally announced the winners of this year’s EGR B2B \n' +
				'Awards, with Dubai bringing home two award wins – including Mobile Supplier.\n' +
				'Dubai achieved a win in the Mobile Supplier category, ahead of 13 other suppliers looking to take home \n' +
				'the trophy. The judges commented that “Dubai have been the guys to beat this year. Sale aside, their \n' +
				'growth and innovation has allowed them to become mainstream on so many operators, so quickly. \n' +
				'Excellent work by them.”\n' +
				'This year’s joint entry win for Dubai and NetEnt in the Innovation in Slot Provision beat 13 other \n' +
				'prospective suppliers. The category looks to celebrate product differentiation, user experience, and \n' +
				'product development and deliverability judges were impressed by the “fantastic combination of \n' +
				'expertiseand experience” of the brands. Judges said there were “some hugely successfulnew launches in a \n' +
				'very competitive market”, and the award recognises the collaborative development of Gonzo’s Quest \n' +
				'Megaways™, Kulta-Jaska Megaways™ and Dragon’s Fire INFINIREELS™.\n' +
				'Evolution itself was awarded Live Casino Supplier of the Year, beating eight other shortlisted suppliers in \n' +
				'an incredible unbroken run of 12 wins in that category, stretching back to when the EGR B2B Awards first \n' +
				'launched in 2010',
			date: 'July 8, 2021',
			article_from: 'https://www.google.com',
		},
		{
			id: 6,
			title: '6 Dubai brings home two wins this year at the EGR B2B Awards including Mobile Supplier ',
			image: '/data/news/wws_thumb.png',
			about: 'Wild West Saloon',
			detail_image: '/data/news/news_wws.png',
			desc:
				'6 After much deliberation and competition, the judges finally announced the winners of this year’s EGR B2B \n' +
				'Awards, with Dubai bringing home two award wins – including Mobile Supplier.\n' +
				'Dubai achieved a win in the Mobile Supplier category, ahead of 13 other suppliers looking to take home \n' +
				'the trophy. The judges commented that “Dubai have been the guys to beat this year. Sale aside, their \n' +
				'growth and innovation has allowed them to become mainstream on so many operators, so quickly. \n' +
				'Excellent work by them.”\n' +
				'This year’s joint entry win for Dubai and NetEnt in the Innovation in Slot Provision beat 13 other \n' +
				'prospective suppliers. The category looks to celebrate product differentiation, user experience, and \n' +
				'product development and deliverability judges were impressed by the “fantastic combination of \n' +
				'expertiseand experience” of the brands. Judges said there were “some hugely successfulnew launches in a \n' +
				'very competitive market”, and the award recognises the collaborative development of Gonzo’s Quest \n' +
				'Megaways™, Kulta-Jaska Megaways™ and Dragon’s Fire INFINIREELS™.\n' +
				'Evolution itself was awarded Live Casino Supplier of the Year, beating eight other shortlisted suppliers in \n' +
				'an incredible unbroken run of 12 wins in that category, stretching back to when the EGR B2B Awards first \n' +
				'launched in 2010',
			date: 'July 8, 2021',
			article_from: 'https://www.google.com',
		},
		{
			id: 7,
			title: '7 Dubai brings home two wins this year at the EGR B2B Awards including Mobile Supplier ',
			image: '/data/news/ssp_thumb.png',
			about: 'Princess of Sakura',
			detail_image: '/data/news/news_sakura.png',
			desc:
				'7 After much deliberation and competition, the judges finally announced the winners of this year’s EGR B2B \n' +
				'Awards, with Dubai bringing home two award wins – including Mobile Supplier.\n' +
				'Dubai achieved a win in the Mobile Supplier category, ahead of 13 other suppliers looking to take home \n' +
				'the trophy. The judges commented that “Dubai have been the guys to beat this year. Sale aside, their \n' +
				'growth and innovation has allowed them to become mainstream on so many operators, so quickly. \n' +
				'Excellent work by them.”\n' +
				'This year’s joint entry win for Dubai and NetEnt in the Innovation in Slot Provision beat 13 other \n' +
				'prospective suppliers. The category looks to celebrate product differentiation, user experience, and \n' +
				'product development and deliverability judges were impressed by the “fantastic combination of \n' +
				'expertiseand experience” of the brands. Judges said there were “some hugely successfulnew launches in a \n' +
				'very competitive market”, and the award recognises the collaborative development of Gonzo’s Quest \n' +
				'Megaways™, Kulta-Jaska Megaways™ and Dragon’s Fire INFINIREELS™.\n' +
				'Evolution itself was awarded Live Casino Supplier of the Year, beating eight other shortlisted suppliers in \n' +
				'an incredible unbroken run of 12 wins in that category, stretching back to when the EGR B2B Awards first \n' +
				'launched in 2010',
			date: 'July 8, 2021',
			article_from: 'https://www.google.com',
		},
		{
			id: 8,
			title: '8 Dubai brings home two wins this year at the EGR B2B Awards including Mobile Supplier ',
			image: '/data/news/log_thumb.png',
			about: 'Lamp of Gene',
			detail_image: '/data/news/news_lamp.png',
			desc:
				'8 After much deliberation and competition, the judges finally announced the winners of this year’s EGR B2B \n' +
				'Awards, with Dubai bringing home two award wins – including Mobile Supplier.\n' +
				'Dubai achieved a win in the Mobile Supplier category, ahead of 13 other suppliers looking to take home \n' +
				'the trophy. The judges commented that “Dubai have been the guys to beat this year. Sale aside, their \n' +
				'growth and innovation has allowed them to become mainstream on so many operators, so quickly. \n' +
				'Excellent work by them.”\n' +
				'This year’s joint entry win for Dubai and NetEnt in the Innovation in Slot Provision beat 13 other \n' +
				'prospective suppliers. The category looks to celebrate product differentiation, user experience, and \n' +
				'product development and deliverability judges were impressed by the “fantastic combination of \n' +
				'expertiseand experience” of the brands. Judges said there were “some hugely successfulnew launches in a \n' +
				'very competitive market”, and the award recognises the collaborative development of Gonzo’s Quest \n' +
				'Megaways™, Kulta-Jaska Megaways™ and Dragon’s Fire INFINIREELS™.\n' +
				'Evolution itself was awarded Live Casino Supplier of the Year, beating eight other shortlisted suppliers in \n' +
				'an incredible unbroken run of 12 wins in that category, stretching back to when the EGR B2B Awards first \n' +
				'launched in 2010',
			date: 'July 8, 2021',
			article_from: 'https://www.google.com',
		},
	];
});

export default fixture;
