
import { defineComponent } from 'vue';
import '../mocks/news';
import axios from 'axios';
import Article from '../models/Article';
import { useI18n } from 'vue-i18n';

const apiServer = process.env.VUE_APP_API_URL;

export default defineComponent({
	name: 'News',
	data() {
		return {
			page: 0,
			news: [] as Article[],
			detailOpened: false,
			article: {} as Partial<Article>,
		};
	},
	methods: {
		async getList(): Promise<void> {
			const response = await axios.get(apiServer + '/api/news');
			this.news = response.data;
			this.page = 1;
		},
		async getMore(): Promise<void> {
			const response = await axios.get(apiServer + '/api/news/more/' + (this.page + 1));
			this.news = [...this.news, ...response.data];
			this.page++;
		},
		showDetail(id: string): void {
			let findArticle = this.news.find((article) => article.id == id);
			if (findArticle) {
				this.article = findArticle;
				this.detailOpened = true;
			}
		},
		hideDetail(): void {
			this.detailOpened = false;
		},
	},
	async created() {
		await this.getList();
	},
	setup() {
		const { t } = useI18n({
			userScope: 'global',
			inheritLocale: true,
		});
		return { t };
	},
});
