import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e8258a16"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "section_top" }
const _hoisted_2 = { class: "section_title" }
const _hoisted_3 = { class: "news_list" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "news_thumbnail" }
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = { class: "news_info" }
const _hoisted_8 = { class: "more_view" }
const _hoisted_9 = {
  key: 0,
  class: "news_detail"
}
const _hoisted_10 = { class: "news_pop_container" }
const _hoisted_11 = { class: "detail_info" }
const _hoisted_12 = { class: "news_desc" }
const _hoisted_13 = {
  key: 1,
  class: "dim"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t('news.title')), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.news, (article) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "fade_in_up",
              key: article.id
            }, [
              _createElementVNode("a", {
                href: "#",
                onClick: ($event: any) => (_ctx.showDetail(article.id))
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("img", {
                    src: article.image,
                    alt: article.about
                  }, null, 8, _hoisted_6)
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("dl", null, [
                    _createElementVNode("dt", null, _toDisplayString(article.title), 1),
                    _createElementVNode("dd", null, _toDisplayString(article.date), 1)
                  ])
                ])
              ], 8, _hoisted_4)
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("button", {
          type: "button",
          class: "load_btn",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.getMore && _ctx.getMore(...args)))
        }, _toDisplayString(_ctx.t('news.load_more_button')), 1)
      ])
    ]),
    (_ctx.detailOpened)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("a", {
              href: "javascript:void(0)",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.hideDetail && _ctx.hideDetail(...args))),
              class: "pop_close"
            }),
            _createElementVNode("div", {
              class: "news_visual",
              style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.article.detail_image + ')' })
            }, null, 4),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("dl", null, [
                _createElementVNode("dt", null, _toDisplayString(_ctx.article.title), 1),
                _createElementVNode("dd", null, _toDisplayString(_ctx.article.date), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.article.desc), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.detailOpened)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13))
      : _createCommentVNode("", true)
  ], 64))
}